import React, { useEffect } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import ParticlesAuth from '../ParticlesAuth'
import { Button } from 'reactstrap'
// Import Images
import error from '../../../assets/images/403-error.png'
import ParticlesAuthHeader from '../../../Components/Common/ParticlesAuthHeader'
import { getUserRoles } from '../../../helpers/api_helper'
import { useAppDispatch } from '../../../hooks/redux'
import { setPermissions } from '../../../slices/auth/user/reducer'

const Error403 = ({ onGoBack }: { onGoBack: () => void }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    getUserRoles().then(res => {
      dispatch(setPermissions(res.data))
    })
  }, [])

  return (
    <div className='auth-page-wrapper'>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <ParticlesAuthHeader />
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <img
                        src={error}
                        alt=''
                        className='error-basic-img'
                        height={210}
                      />
                      <div className='mt-n4 pt-5'>
                        <h2 className='error-message mb-2 fw-light'>
                          No Access
                        </h2>
                        <p className='error-message text-muted mb-4 px-2 fw-light'>
                          Unfortunately, you do not have enough permissions to
                          view this page. Contact to your admin to unlock this
                          page.
                        </p>
                        <Button
                          color={'primary'}
                          className='btn btn-primary'
                          onClick={onGoBack}
                        >
                          <span className='d-flex'>
                            <i className='ri-arrow-left-s-line me-1'></i>
                            <span>Go Back</span>
                          </span>
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </div>
  )
}

export default Error403
