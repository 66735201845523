import React from 'react'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { Step3PassedProps } from './types'
import _ from 'lodash'
import { getAssignedCode } from '../../../helpers/course'
import moment from 'moment'
import { TCoursePackage } from '../../../sharedTypes/models/coursePackage'

const Step3Passed = ({ assignOptions }: Step3PassedProps) => {
  const LIMIT = 2

  return (
    <div className='me-1'>
      <Row className='p-3 text-start bg-body rounded'>
        {!_.isEmpty(assignOptions.digital) && (
          <Col sm={6}>
            <div className='v-stack gap-2'>
              <span className='text-muted fs-14'>
                Digital Courses and Competency Only :{' '}
              </span>

              <div className='hstack'>
                <span className='text-body'>
                  {assignOptions.digital.slice(0, LIMIT).map((item, index) => (
                    <div
                      key={index}
                      className='d-flex align-items-baseline selected-course-details rounded mb-1'
                    >
                      <Col className='badge selected-course-badge text-muted fs-12 fw-normal me-1 text-start'>
                        {getAssignedCode(item)}
                      </Col>
                      <Col
                        className={`badge selected-course-badge text-muted fs-12 fw-normal me-1
                       mt-1 text-truncate text-start`}
                      >
                        {_.get(item.course, 'translations[0].content.name')}:{' '}
                      </Col>
                      <Col className='badge selected-course-badge text-muted fs-12 fw-normal me-1 text-start'>
                        {`${moment(item.dateAvailable).format('MM/DD/YYYY')}
                        - ${moment(item.dueDate).format('MM/DD/YYYY')}`}
                      </Col>
                    </div>
                  ))}

                  {assignOptions.digital.length > LIMIT && (
                    <>
                      <UncontrolledTooltip
                        placement='top'
                        target={'viewMore'}
                        style={{ minWidth: 250, textAlign: 'left' }}
                      >
                        {assignOptions.digital.map((item, index) => (
                          <div key={index}>
                            {getAssignedCode(item)} :{' '}
                            {_.get(item.course, 'translations[0].content.name')}
                          </div>
                        ))}
                      </UncontrolledTooltip>

                      <span
                        className='badge selected-course-badge text-primary cursor-pointer ps-1'
                        id={'viewMore'}
                      >
                        +{assignOptions.digital.length - LIMIT} more
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          </Col>
        )}

        {!_.isEmpty(assignOptions.live) && (
          <Col sm={6}>
            <div className='v-stack gap-2'>
              <span className='text-muted fs-14'>
                In-Person and Virtual Conference Courses :{' '}
              </span>
              <div className='hstack'>
                <span className='text-body'>
                  {assignOptions.live.slice(0, LIMIT).map((item, index) => (
                    <div
                      key={index}
                      className='d-flex align-items-baseline selected-course-details rounded mb-1'
                    >
                      <Col
                        className={`badge selected-course-badge text-muted fs-12 fw-normal me-1 text-start
                       mt-1 text-truncate`}
                      >
                        {(item.course as TCoursePackage).name ??
                          _.get(
                            item.course,
                            'translations[0].content.name',
                            '-',
                          )}
                        :
                      </Col>
                      <Col className='badge selected-course-badge text-muted fs-12 fw-normal me-1 text-start'>
                        {getAssignedCode(item)}
                      </Col>
                      <Col className='badge selected-course-badge text-muted fs-12 fw-normal me-1 text-start'>
                        {`${moment(item.dueDate).format('MM/DD/YYYY hh:mm A')}`}
                      </Col>
                    </div>
                  ))}

                  {assignOptions.live.length > LIMIT && (
                    <>
                      <UncontrolledTooltip
                        placement='top'
                        target={'viewMore'}
                        style={{ minWidth: 250, textAlign: 'left' }}
                      >
                        {assignOptions.live.map((item, index) => (
                          <div key={index}>
                            {getAssignedCode(item)} :{' '}
                            {(item.course as TCoursePackage).name ??
                              _.get(
                                item.course,
                                'translations[0].content.name',
                                '-',
                              )}
                          </div>
                        ))}
                      </UncontrolledTooltip>

                      <span
                        className='badge selected-course-badge text-primary cursor-pointer ps-1'
                        id={'viewMore'}
                      >
                        +{assignOptions.live.length - LIMIT} more
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Step3Passed
