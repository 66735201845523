import { GroupMultiSelectWithSearch } from './SelectWithSearch'
import { Option } from '../../helpers/facility'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAppSelector } from '../../hooks/redux'

interface FacilitiesMultiSelectDropdownProps {
  selectedOptions: Option[]
  setSelectedOptions: Dispatch<SetStateAction<Option[]>>
  isDisabled?: boolean
  label?: string
}

const FacilitiesMultiSelectDropdown = ({
  setSelectedOptions,
  selectedOptions,
  isDisabled = false,
  label = 'Filter by Facility',
}: FacilitiesMultiSelectDropdownProps) => {
  const { groupOptions } = useAppSelector(state => state.FacilityOptions)
  const user = useAppSelector(state => state.User.user)

  const [options, setOptions] = useState<Option[]>(groupOptions)

  useEffect(() => {
    const { options } = disableChildren(selectedOptions)

    setOptions(options)
  }, [selectedOptions])

  const collectDisabledIds = (
    option: Option,
    selectedValues: Set<string>,
    disabledIds: Set<string>,
  ) => {
    if (selectedValues.has(option.parentValue || '')) {
      disabledIds.add(option.value)
    }
  }

  const disableRecursive = (
    option: Option,
    selectedValues: Set<string>,
    disabledIds: Set<string>,
  ): Option => {
    const updatedOption = { ...option }
    collectDisabledIds(option, selectedValues, disabledIds)

    if (option.parentValue && selectedValues.has(option.parentValue)) {
      updatedOption.disabled = true
    }

    if (disabledIds.has(option.parentValue || '')) {
      updatedOption.disabled = true
    }

    return updatedOption
  }

  const disableChildren = (selectedOptions: Option[]) => {
    const selectedValues = new Set(
      (selectedOptions || []).map(option => option.value),
    )
    const disabledIds = new Set<string>()
    let _options: Option[] = options.map(option => ({
      ...option,
      disabled: false,
    }))

    if (user?.groupId) {
      _options = _options.filter(option => option.level !== 0)
    }

    const allSelected = selectedOptions.some(option => option.level === 0)

    if (allSelected) {
      return {
        options: _options.map(option => ({
          ...option,
          disabled: option.level !== 0,
        })),
        disabledIds,
      }
    }
    return {
      options: _options.map(option =>
        disableRecursive(option, selectedValues, disabledIds),
      ),
      disabledIds,
    }
  }

  return (
    <div>
      <label htmlFor='facility' className='form-label'>
        {label}
      </label>
      <GroupMultiSelectWithSearch<Option>
        name='group'
        id='group'
        onChange={option => {
          if (option) {
            const { disabledIds } = disableChildren(option as Option[])
            const allSelected = (option as Option[]).find(
              item => item.level === 0,
            )
            if (allSelected) {
              setSelectedOptions([allSelected])
            } else {
              const filteredSelectedOptions = (option as Option[]).filter(
                item => !disabledIds.has(item.value),
              )
              setSelectedOptions(filteredSelectedOptions)
            }
          }
        }}
        placeholder={'Select facilities'}
        hideSelectedOptions={false}
        isDisabled={isDisabled}
        isMulti
        value={selectedOptions}
        isClearable={false}
        isSearchable={true}
        options={options}
      />
    </div>
  )
}

export default FacilitiesMultiSelectDropdown
