export enum ASSIGNMENT_FILTER_TYPES {
  OVERDUE = 'overdue',
  NO_MORE_ATTEMPTS = 'noAttempts',
}

export enum EXPORT_TYPES {
  CSV = 'CSV',
  XLS = 'XLS',
  PDF = 'PDF',
}

export enum EXPORT_RANGE {
  ALL_TIME = 'all',
  DATE_RANGE = 'range',
}

export interface DateRangeData {
  startDate?: Date
  endDate?: Date
}

export interface SelectedFilters {
  facilities?: string[]
  groups?: string[]
  positions: string[]
  departments: string[]
  courses: string[]
}
