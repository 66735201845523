import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import _ from 'lodash'

export interface FilterTabsProps<EnumValue> {
  navTab?: string | undefined
  navToggle: (tab?: EnumValue) => void
  tabs: { label: string; value: EnumValue | undefined }[]
  style?: React.CSSProperties
  extraTabData?: { [key: string]: string | number }
}
const FilterTabs: <SortBy>(
  props: FilterTabsProps<SortBy>,
) => React.ReactElement<FilterTabsProps<SortBy>> = ({
  navTab,
  navToggle,
  tabs,
  extraTabData,
  style,
}) => {
  return (
    <Nav className='nav-customs-bordered m-auto' style={style}>
      {tabs.map(tab => (
        <NavItem
          key={tab.label}
          className={classnames({ active: navTab === tab.value })}
        >
          <NavLink
            style={{ cursor: 'pointer' }}
            className={classnames({ active: navTab === tab.value })}
            onClick={() => {
              navToggle(tab.value)
            }}
          >
            {tab.label}
            {!!_.get(extraTabData, tab.value as string) && (
              <span className='ps-1'>
                ({_.get(extraTabData, tab.value as string)})
              </span>
            )}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export default FilterTabs
