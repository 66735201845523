import React, { Fragment, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Table } from 'reactstrap'
import {
  TUser,
  USER_STATUSES,
  UserManagementPermissions,
} from '../../../sharedTypes'
import moment from 'moment'
import { getUserDisplayName } from '../../../helpers/user'
import { usePermissions } from '../../../hooks/usePermissions'
import EditRequestModal from '../../Authentication/UserProfile/EditRequestModal'
import GrantAdminPortalAccessModal from '../../../Components/Modals/GrantAdminPortalAccessModal'
import { BadgeColorByCourseStatus } from '../../../Components/Common/UsersTable'
import ManageCredentials from './ManageCredentials'
import UserDepartments from '../../../Components/Common/UserDepartments'

interface UserInfoProps {
  user: TUser
  onDeactivatePress: () => void
}

const UserInfo = ({ user, onDeactivatePress }: UserInfoProps) => {
  const [editRequest, setEditRequest] = useState<boolean>(false)
  const [isAdmin, setIsAdmin] = useState<boolean>(!!user.roleId)
  const [showGrantAdminPortalAccessModal, setShowGrantAdminPortalAccessModal] =
    useState<boolean>(false)

  const hasPermissionToDeactivate = usePermissions(
    UserManagementPermissions.DEACTIVATE_USER,
  )
  const hasPermissionToViewUserInfo = usePermissions(
    UserManagementPermissions.VIEW_USER_INFO,
  )
  const hasPermissionToEdit = usePermissions(
    UserManagementPermissions.REQUEST_EDIT_OF_USER_INFO,
  )

  const hasPermissionToGrandAdminPortalAccess = usePermissions(
    UserManagementPermissions.GRAND_ADMIN_PORTAL_ACCESS,
  )

  const showGrandAdminPortalAccess =
    hasPermissionToGrandAdminPortalAccess &&
    !user.deactivated &&
    user.status === USER_STATUSES.ACTIVE &&
    !isAdmin

  const info = useMemo(() => {
    const data = [
      /* {
        label: 'Points',
        value: (
          <span className='badge badge-soft-primary fs-12 fw-normal'>
            15 207 XP
          </span>
        ),
      },*/
      {
        label: 'Status',
        value: (
          <span
            className={`badge badge-soft-${
              BadgeColorByCourseStatus[user.courseStatus]
            } fs-12 fw-normal text-capitalize`}
          >
            {user.courseStatus}
          </span>
        ),
        render: true,
      },
      {
        label: 'Department',
        value: (
          <UserDepartments
            departments={user.departments || []}
            key={''}
            className='text-muted'
          />
        ),
        render: true,
      },
      {
        label: 'Employment Status',
        value: user.employeeType
          ? user.employeeType.charAt(0).toUpperCase() +
            user.employeeType.slice(1)
          : '',
        render: hasPermissionToViewUserInfo,
      },
      {
        label: 'Birthdate',
        value: user.dob ? moment(user.dob).format('MM/DD/YYYY') : '',
        render: hasPermissionToViewUserInfo,
      },
      {
        label: 'Hire Date',
        value: user.hireDate ? moment(user.hireDate).format('MM/DD/YYYY') : '',
        render: true,
      },
      {
        label: 'Facility',
        value: user.facility?.name,
        render: true,
      },
      {
        label: 'Address',
        value: user.address,
        render: hasPermissionToViewUserInfo,
      },
    ]

    return data.filter(item => item.render)
  }, [user, hasPermissionToViewUserInfo])

  return (
    <Fragment>
      <EditRequestModal
        onClose={() => {
          setEditRequest(false)
        }}
        isOpen={editRequest}
        userId={user.id}
      />
      {showGrandAdminPortalAccess && (
        <GrantAdminPortalAccessModal
          isOpen={showGrantAdminPortalAccessModal}
          onClose={() => setShowGrantAdminPortalAccessModal(false)}
          onAccessGranted={() => setIsAdmin(true)}
          userId={user.id}
        />
      )}
      <Col xl={3} lg={4} className='col'>
        <Card>
          <CardBody className='d-flex gap-4 flex-column'>
            <div className='d-flex align-items-center'>
              <div className='flex-grow-1'>
                <p className='fs-16 mb-0 fw-light' style={{ color: '#7C8193' }}>
                  User Info
                </p>
              </div>
              <div className='flex-shrink-0 fs-12'>
                {hasPermissionToDeactivate && (
                  <Button
                    color='soft-danger'
                    className='me-2 fs-12'
                    onClick={onDeactivatePress}
                  >
                    <i className='ri-close-line me-1'></i>Deactivate
                  </Button>
                )}
                {hasPermissionToEdit && (
                  <Button
                    color='soft-primary'
                    className='fs-12'
                    onClick={() => {
                      setEditRequest(true)
                    }}
                  >
                    <i className='ri-edit-box-line me-1'></i>Edit
                  </Button>
                )}
              </div>
            </div>
            <div className='m-auto d-flex gap-3 flex-column align-items-center'>
              {/*<img*/}
              {/*  src={*/}
              {/*    user?.photo || user.gender === 'male'*/}
              {/*      ? avatarMale*/}
              {/*      : avatarFemale*/}
              {/*  }*/}
              {/*  alt={user.firstName[0] + user.lastName[0]}*/}
              {/*  className='img-thumbnail avatar-xl rounded-circle'*/}
              {/*/>*/}
              <div className='d-flex gap-1 flex-column align-items-center'>
                <span className='fs-16 fw-light'>
                  {getUserDisplayName(user)}
                </span>
                <span className='text-muted fw-light'>
                  {user.position?.name}
                </span>
                <span className='text-muted fw-light'>ID {user.id}</span>
              </div>
            </div>
            <div className='table-responsive'>
              {info.map((item, index) => (
                <div key={index} className='d-flex'>
                  <div className='ps-0 fs-14 fw-normal text-nowrap user-info-key'>
                    {item.label} :
                  </div>
                  <div className='text-muted fw-light user-info-value'>
                    {item.value}
                  </div>
                </div>
              ))}
              <ManageCredentials
                user={user}
                hasPermissionToViewUserInfo={hasPermissionToViewUserInfo}
              />
            </div>
            {showGrandAdminPortalAccess && (
              <Button
                className='btn-soft-primary align-middle'
                onClick={() => setShowGrantAdminPortalAccessModal(true)}
              >
                <i className='ri-shield-keyhole-line me-1 fs-16'></i>
                Grant Admin Portal Access
              </Button>
            )}
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  )
}

export default UserInfo
