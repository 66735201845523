import React, {
  useCallback,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
} from 'react'
import { useFormik, FormikProps } from 'formik'
import {
  putOnlineCourse,
  getLanguages,
  getInstructors,
  getOnlineTags,
  getOnlineCategories,
} from '../../helpers/api_helper'
import {
  MediaModalDetails,
  AttachmentsTypesEnum,
  TAttachment,
  DocumentFile,
  CoursesPermissions,
  OnlineCourseItem,
  COURSE_TYPES,
  StatesOptions,
} from '../../sharedTypes'
import { draftSchema, completeGeneralSchema } from '../../schemas'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../helpers/toast_helper'
import _ from 'lodash'
import { ENGLISH_LANGUAGE_CODE, getMediaDetails } from '../../helpers/common'
import { getUserDisplayName } from '../../helpers/user'
import { MultiValue } from 'react-select'
import { FormFeedback } from 'reactstrap'
import { useShouldGoTo, IGoTo } from '../../hooks/course/useShouldGoTo'
import { AllValue } from '../../Components/Modals/AssignCourseModal/types'
import {
  putOnlineCourseImages,
  deleteOnlineCourseImages,
} from '../../helpers/api_helper'
import moment from 'moment/moment'

interface Option {
  value: number | string
  label: string | number
}

interface IForm {
  duration: number
  level: null | Option
  code: string
  tagIds: Option[]
  effectiveDate?: string
  expirationDate?: string
  type: COURSE_TYPES | null
  instructorId: null | Option
  coverId: number | null
  feedbackSurvey: boolean
  feedbackAnonymous: boolean
  languages: number[]
  certificateRequired: boolean
  states: Option[]
  translations: any[]
  cover: DocumentFile | null
}

interface IPrehandleSubmit {
  direction?: 'next' | 'prev' | string | undefined
  discard?: boolean
}

export const isAllOption = (options: any) =>
  _.find(options, { value: AllValue.value })

export interface IUseGeneralStep {
  form: FormikProps<IForm>
  languages: Option[]
  hasUnsavedData: boolean
  selectedLanguages: Option[]
  selectedLanguage: Option
  setSelectedLanguage: Dispatch<SetStateAction<Option>>
  fetchInstructors: (inputValue?: string) => Promise<Option[]>
  fetchOnlineCategories: (inputValue?: string) => Promise<Option[]>
  fetchTags: (inputValue?: string) => Promise<Option[]>
  handleLanguageChange: (options: MultiValue<Option>) => void
  onPreHandleSubmit: (props: IGoTo) => void
  mediaModalDetails: MediaModalDetails
  setMediaModalDetails: Dispatch<SetStateAction<MediaModalDetails>>
  translationError: (index: number, field: string) => JSX.Element | null
  translationTabError: (languageId: number) => JSX.Element | null
  onDiscardChanges: () => void
  onDeleteCover: () => void
  handleCropSave: (attachment: TAttachment, images: any) => void
}

export const useGeneralStep = ({
  course,
  setCourse,
  goNext,
  onBack,
  isPublishing,
}: {
  course: OnlineCourseItem | null
  goNext: () => void
  onBack: () => void
  setCourse: Dispatch<SetStateAction<OnlineCourseItem | null>>
  isPublishing: boolean
}): IUseGeneralStep => {
  const { setShouldGoTo, onSuccess } = useShouldGoTo({ goNext, onBack })
  const [initialValue, setInitialValue] = useState<IForm | null>()
  const [languages, setLanguages] = useState<Option[]>([])
  const [selectedLanguages, setSelectedLanguages] = useState<Option[]>([])
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null)
  const [mediaModalDetails, setMediaModalDetails] = useState<MediaModalDetails>(
    {
      isOpen: false,
      mediaProperty: 'cover',
      type: [AttachmentsTypesEnum.IMAGE],
    },
  )
  const validationSchema = useMemo(
    () => (isPublishing ? completeGeneralSchema : draftSchema),
    [isPublishing],
  )

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const payload = prepareFormData(values)
        const data = await putOnlineCourse(Number(course?.id), payload)
        setCourse(data)
        onSuccess()
        toast(
          'Success - General information successfully updated',
          successToastOptions,
        )
      } catch (e) {}
    },
    [course?.id, onSuccess],
  )
  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      duration: 0,
      effectiveDate: undefined,
      expirationDate: undefined,
      level: null,
      code: '',
      tagIds: [],
      coverId: null,
      type: null,
      instructorId: null,
      feedbackSurvey: true,
      feedbackAnonymous: true,
      languages: [],
      certificateRequired: false,
      translations: [],
      states: [],
      cover: null,
    },
    validationSchema,
    onSubmit,
  })
  const onPreHandleSubmit = useCallback(
    (props: IGoTo) => {
      setShouldGoTo(props)
      form.handleSubmit()
    },
    [form],
  )

  const onDeleteCover = useCallback(async () => {
    try {
      await deleteOnlineCourseImages(course?.id as number)
      form.setFieldValue('cover', null)
      form.setFieldValue('coverId', null)
      toast('Success - Cover image deleted', successToastOptions)
    } catch (e) {}
  }, [course])

  const prepareFormData = useCallback((values: IForm): FormData => {
    const formData: any = new FormData()

    const { cover } = values
    const instructorId = _.get(values.instructorId, 'value')
    const level = _.get(values.level, 'value')

    _.map(values.tagIds, 'value').map(t => {
      formData.append('tagIds[]', t)
    })

    formData.append(
      'translations',
      JSON.stringify(
        values.translations.map((t: any) => ({
          languageId: t.languageId,
          content: {
            name: t.name,
            objective: t.objective,
            description: t.description,
            notes: t.notes,
            resources: t.resources,
          },
        })),
      ),
    )
    StatesOptions
    formData.append(
      'states',
      JSON.stringify(
        _.map(
          isAllOption(values.states) ? StatesOptions : values.states,
          'value',
        ),
      ),
    )

    if (values.effectiveDate) {
      formData.append(
        'effectiveDate',
        moment(values.effectiveDate).format('YYYY-MM-DD'),
      )
    }

    if (values.expirationDate) {
      formData.append(
        'expirationDate',
        moment(values.expirationDate).format('YYYY-MM-DD'),
      )
    }

    formData.append('certificateRequired', values.certificateRequired)
    formData.append('feedbackAnonymous', values.feedbackAnonymous)
    formData.append('feedbackSurvey', values.feedbackSurvey)
    formData.append('duration', values.duration)
    formData.append('code', values.code)
    if (instructorId) {
      formData.append('instructorId', instructorId)
    }

    formData.append('type', values.type)

    if (level) {
      formData.append('level', level)
    }
    if (cover?.blob) {
      formData.append('cover', cover.blob ?? cover, cover.name)
    } else {
      formData.append('coverId', values.coverId)
    }

    return formData
  }, [])

  useEffect(() => {
    getLanguages()
      .then(list => {
        const options = list.map(l => ({ value: l.id, label: l.name }))
        setLanguages(options)
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    setInitialFormValues()
  }, [course])

  const onDiscardChanges = useCallback(() => {
    setInitialFormValues()
  }, [course])

  const setInitialFormValues = useCallback(() => {
    if (course) {
      handleLanguageChange(
        course.translations.map(t => ({
          label: t.language.name,
          value: t.language.id,
        })),
      )

      const values: Partial<IForm> = {}

      values.feedbackAnonymous = course.feedbackAnonymous
      values.feedbackSurvey = course.feedbackSurvey
      values.certificateRequired = course.certificateRequired
      values.cover = course.cover ? getMediaDetails(course.cover) : course.cover
      values.coverId = course.coverId
      values.code = course.code
      values.duration = course.duration
      values.states = course.states.map(s => ({ label: s, value: s }))
      values.states = _.isEqual(course.states, _.map(StatesOptions, 'value'))
        ? [AllValue]
        : course.states.map(s => ({ label: s, value: s }))

      values.translations = course.translations.map((t: any) => ({
        name: t?.content?.name || '',
        objective: t.content?.objective || '',
        description: t.content?.description || '',
        notes: t.content?.notes || '',
        resources: t.content?.resources || '',
        languageId: t.languageId || '',
      }))

      values.tagIds = course.tags.map((t: any) => ({
        label: t.name,
        value: t.CoursesTags.courseTagId,
      }))

      if (course.level) {
        values.level = {
          label: _.capitalize(course.level),
          value: course.level,
        }
      }

      if (course.effectiveDate) {
        values.effectiveDate = course.effectiveDate
      }

      if (course.expirationDate) {
        values.expirationDate = course.expirationDate
      }

      values.type = course.type

      if (course?.instructor) {
        values.instructorId = {
          label: getUserDisplayName(course.instructor),
          value: course.instructorId,
        }
      }

      form.setValues(values as IForm, true)
      setInitialValue(values as IForm)
      const defaultLanguage = course.translations?.find(
        trans => trans.language.code === ENGLISH_LANGUAGE_CODE,
      )
      if (defaultLanguage) {
        setSelectedLanguage(defaultLanguage.languageId)
      }
    }
  }, [course])

  useEffect(() => {
    if (selectedLanguages.length === 1) {
      setSelectedLanguage(selectedLanguages[0].value)
    }
  }, [selectedLanguages])

  const fetchInstructors = (inputValue?: string): Promise<Option[]> => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return getInstructors({
      ...params,
      permission: CoursesPermissions.ADD_EDIT_COURSE,
    })
      .then(({ instructors }) => {
        return instructors.map(instructor => ({
          value: instructor.id,
          label: getUserDisplayName(instructor),
        }))
      })
      .catch(() => [])
  }

  const handleCropSave = useCallback(
    async (attachment: TAttachment, images: any) => {
      try {
        const formData = new FormData()
        formData.append('attachmentId', String(attachment.id))

        Object.keys(images).forEach(key => {
          formData.append(key, images[key])
        })

        await putOnlineCourseImages(course?.id as number, formData)

        form.setFieldValue('cover', getMediaDetails(attachment))

        toast('Success - Cover image uploaded', successToastOptions)
      } catch (e) {
        console.log(e, 'error')
      }
    },
    [course],
  )

  const fetchTags = (inputValue?: string): Promise<Option[]> => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return getOnlineTags(params)
      .then(({ online_tags }) => {
        return online_tags.map(tag => ({
          value: tag.id,
          label: tag.name,
        }))
      })
      .catch(() => [])
  }

  const fetchOnlineCategories = (inputValue?: string): Promise<Option[]> => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return getOnlineCategories(params)
      .then(({ online_categories }) => {
        return online_categories.map(tag => ({
          value: tag.id,
          label: tag.name,
        }))
      })
      .catch(() => [])
  }

  const handleLanguageChange = useCallback(
    (options: MultiValue<Option>) => {
      const languageIds = _.map(options, 'value')
      form.setFieldValue('languages', options)
      form.handleBlur('languages')

      form.setFieldValue(
        'translations',
        !_.size(languageIds)
          ? []
          : form.values.translations.filter((t: any) =>
              languageIds.includes(t.languageId),
            ),
      )

      languageIds.forEach((languageId: any) => {
        const exists = form.values.translations.find(
          (l: any) => l.languageId === languageId,
        )
        if (!exists) {
          form.setFieldValue('translations', [
            ...form.values.translations,
            {
              name: '',
              description: '',
              objective: '',
              languageId,
            },
          ])
        }
      })

      setSelectedLanguages(options as any)
    },
    [form.values],
  )

  const translationError = useCallback(
    (index: number, field: string) => {
      const error = _.get(form, `errors.translations[${index}].${field}`)
      const touched = _.get(form, `touched.translations[${index}].${field}`)

      return error && touched ? (
        <FormFeedback type='invalid'>{error}</FormFeedback>
      ) : null
    },
    [form],
  )

  const translationTabError = useCallback(
    (languageId: number) => {
      const index = form.values.translations.findIndex(
        (t: any) => t.languageId === languageId,
      )
      return (
        translationError(index, 'name') ||
        translationError(index, 'description')
      )
    },
    [form],
  )

  const hasUnsavedData = useMemo(
    () => JSON.stringify(form.values) !== JSON.stringify(initialValue),
    [initialValue, form.values],
  )
  return {
    form,
    languages,
    hasUnsavedData,
    selectedLanguages,
    selectedLanguage,
    mediaModalDetails,
    setMediaModalDetails,
    setSelectedLanguage,
    fetchInstructors,
    translationError,
    translationTabError,
    fetchOnlineCategories,
    fetchTags,
    handleLanguageChange,
    onPreHandleSubmit,
    onDiscardChanges,
    handleCropSave,
    onDeleteCover,
  }
}
