import { FilterOption } from '../../sharedTypes'
import Select, { components, MultiValueProps, OptionProps } from 'react-select'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Input, UncontrolledTooltip } from 'reactstrap'
import { GroupBase } from 'react-select/dist/declarations/src/types'
import _ from 'lodash'

interface CustomMultiValueProps extends MultiValueProps<FilterOption, true> {
  containerWidth: number
}

interface MoreSelectedBadgeProps {
  items: number
  overflowNames: string[]
  className: string
}

export const MultiValue: React.FC<CustomMultiValueProps> = ({
  index,
  getValue,
  containerWidth,
  ...props
}) => {
  const [visibleItems, setVisibleItems] = useState<number>(0)

  const data = useMemo(() => {
    return getValue()
  }, [getValue])

  useEffect(() => {
    const calculateVisibleItems = () => {
      let visibleItems = 0
      let totalWidth = 0

      for (const item of data) {
        const itemWidth = getTextWidth(item.label)
        if (totalWidth + itemWidth <= containerWidth - 30 - 16) {
          visibleItems++
          totalWidth += itemWidth
        } else {
          break
        }
      }

      setVisibleItems(visibleItems)
    }

    const getTextWidth = (text: string) => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      if (!context) {
        return 0
      }

      context.font = '12px Outfit'
      return context.measureText(text).width + 9 + 4 + 4 + 23 + 4
    }

    calculateVisibleItems()
  }, [data, containerWidth])

  const maxToShow = visibleItems
  const overflow = getValue().length - visibleItems
  const overflowNames = data
    .slice(visibleItems, data.length)
    .map(item => item.label)

  return index < maxToShow ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    <components.MultiValue {...props} className='text-light-purple' />
  ) : index === maxToShow ? (
    <MoreSelectedBadge
      items={overflow}
      overflowNames={overflowNames}
      className={props.className || ''}
    />
  ) : null
}

const MoreSelectedBadge = ({
  items,
  overflowNames,
  className,
}: MoreSelectedBadgeProps) => {
  return (
    <div className='select2-selection form-select__multi-value css-1p3m7a8-multiValue bg-soft-primary'>
      <UncontrolledTooltip placement='top' target={`moreValues-${className}`}>
        {overflowNames.join(', ')}
      </UncontrolledTooltip>
      <div
        className='select2-selection form-select__multi-value__label css-wsp0cs-MultiValueGeneric text-center px-2 text-primary'
        id={`moreValues-${className}`}
        style={{ textOverflow: 'unset' }}
      >
        +{items}
      </div>
    </div>
  )
}

const Option: <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group>,
) => React.JSX.Element = props => {
  return (
    <>
      <components.Option {...props}>
        <Input
          className='form-check-input me-2'
          type='checkbox'
          name='filterOption'
          checked={props.isSelected}
        />
        {props.children}
      </components.Option>
    </>
  )
}

const MultiSelect = ({ ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={containerRef}>
      <Select<FilterOption, true>
        components={{
          MultiValue: multiValueProps => (
            <MultiValue
              {...multiValueProps}
              className={_.get(props, 'id')}
              containerWidth={
                containerRef.current?.offsetWidth
                  ? containerRef.current?.offsetWidth - 38
                  : 312
              }
            />
          ),
          Option: props => <Option {...props} />,
        }}
        className='select2-container is-invalid'
        classNamePrefix='select2-selection form-select'
        {...props}
      />
    </div>
  )
}

export default MultiSelect
